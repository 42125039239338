<template>
  <div>
    <div v-if="$store.state.show">
      <h2>第三方集成方案</h2>
      <p>
        目前TiSDK正与腾讯、声网、七牛云、金山云、三体云、网易云信、融云、阿里云、即构、环信等第三方技术方案提供商紧密合作，并且TiSDK核心功能是渲染相关部分，支持对
        OpenGL 纹理、byte 数组等数据的渲染，所以能够兼容他们的直播 SDK、短视频
        SDK、连麦 SDK、视频通话
        SDK等，并提供相应的集成Demo参考，以便更方便地集成，如有需要可在技术支持群索要。
      </p>
    </div>
    <div v-else>
      <h2>Third-party integration scheme</h2>
      <p>
        TiSDK is currently working closely with third-party technical solution
        providers such as Tencent Cloud, Agore, Qiniu Cloud, Kingsoft Cloud,
        SanTi Cloud, NetEase Cloud, Rong Cloud, Alibaba Cloud, Zego, and
        Easemob. TiSDK’s core function is to render related parts, support the
        rendering of OpenGL texture, byte array and other data, so it can be
        compatible with their live SDK, short video SDK, Lianmai.SDK, Video Call
        SDK, etc. and provide integrated Demo reference for easier integration.
        Request it in technical support group if needed.
      </p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
h2 {
  font-size: 20px;
  font-weight: 500;
  color: #3c4858;
}
p {
  width: 763px;
  margin: 20px 0;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #3c4858;
}
</style>